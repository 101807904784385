import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from './../environments/environment';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { httpInterceptor } from "./http-interceptor/http.interceptor";
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatInputModule, MatAutocompleteModule } from '@angular/material';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

import { MiniGameComponent } from './mini-game/mini-game.component';
import { DetailComponent } from './mini-game/detail/detail.component';
export class StandardUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
   
   // url = url.replace(/(\r\n|\n|\r)/gm, "");
     url = url.replace(/\(/g, "-").replace(/\)/g, "-");
   //  url = url.replace(/\%20/g, "-");
     url = url.replace(/\%28/g, "-"); 
     url = url.replace(/\%29/g, "-"); 
     url = url.replace(/\%25/g, "-"); 
     url = url.replace(/%3F/g, "-"); 
    // url =  url.replace(/ /g,"-");
     url =  url.replace(/#/g,"-");
     url =  url.replace(/;/g,"-");
    
     //console.log("fffff",url);
     return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
     return this._defaultUrlSerializer.serialize(tree);
  }
}
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MiniGameComponent,
    DetailComponent
  ],
  imports: [
    MatInputModule,
    NgSelectModule,
    MatAutocompleteModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    ToastNotificationsModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    Ng4LoadingSpinnerModule,
    BrowserAnimationsModule,
    
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: 'apiBase', useValue: environment.apiBase},
    {provide: 'siteUrl', useValue: environment.siteUrl},
    {
      provide: UrlSerializer,
      useClass: StandardUrlSerializer
   }
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
