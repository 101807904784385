
<section class="playerView" *ngIf="activetrack"  [routerLink]="activetrack?.slug">
  <span class="playerToggle" (click)="togglePlayerView($event);"><i class="fa fa-angle-down"></i></span>
  <div class="thumbnail"><img src="{{activetrack.image}}" alt="{{activetrack.name}}"></div>
  <div class="text"><span class="h3">{{activetrack.name}}</span><p>{{activetrack.genre}}</p></div>
  <div class="controls" *ngIf="!isloading" >
      <!-- <a href="#"><i class="fa fa-backward"></i></a> -->
     
      <div class="playPause noBg" *ngIf="prevnextdata && currentindex > 1" (click)="prev($event,prevnextdata)"><i class="fa fa-backward"></i></div>
      <!-- <div class="playPause noBg"><i class="fa fa-backward"></i></div> -->
      <div class="playPause toggleplayer"  (click)="toggelPlayer($event,false)" [ngClass]="{disabled: isDisabled}" *ngIf="!isplaying && !isplayerror && !ispageload && !isprevNextplaying"><i class="fa fa-play"></i></div>
      <div class="playPause toggleplayer"  (click)="toggelPlayer($event,true)" [ngClass]="{disabled: isDisabled}"   *ngIf="isplaying && !isplayerror && !ispageload && !isprevNextplaying"><i class="fa fa-pause"></i></div>
      <div class="playPause isplayerror" *ngIf="isplayerror && !isprevNextplaying">      <i class="fa fa-refresh"  (click)="newstart($event,activetrack)"></i></div>

      <div class="playPause ispageload"  *ngIf="ispageload"><i class="fa fa-play"  (click)="newstart($event , activetrack)"></i>
      
      </div>
      <div class="playPause !ispageload"  *ngIf="!ispageload && isprevNext  && isprevNextplaying"><i class="fa fa-play"  (click)="newstart($event , activetrack)"></i>
      
      </div>
      <!-- <a href="#"><i class="fa fa-forward"></i></a> -->
      <div class="playPause noBg" *ngIf="prevnextdata && currentindex !== prevnextdata?.total"  (click)="next($event,prevnextdata)"><i class="fa fa-forward"></i></div>
    </div>
    <div class="controls" *ngIf="isloading">
     <div class="playPause noBg" [attr.disabled]="isloading"  *ngIf="prevnextdata && currentindex> 1"><i class="fa fa-backward"></i></div>
      <div class="playPause"  *ngIf="isloading"><i class="fa fa-spinner fa-spin"></i></div>
      <div class="playPause noBg"   [attr.disabled]="isloading"  *ngIf="prevnextdata && currentindex !== prevnextdata?.total"><i class="fa fa-forward"></i></div> 
  </div>
 
  <div class="volumeControl"  (click)="disabledprop($event)">
      <a href="javascript:void(0)">
        <i *ngIf="!isfav" (click)="favouites(stationId , true)" class="fa fa-heart-o"></i>
        <i *ngIf="isfav" (click)="unfavouites(stationId , false)" class="fa fa-heart"></i>
      </a> 
    
      <a *ngIf="bvolume"  [class.disabled]="isplaying ? null: true" href="javascript:void(0)" (click)="toggelVolume($event,true)"><i class="fa fa-volume-up"></i></a>
      <a *ngIf="!bvolume"   [class.disabled]="!isplaying ? true: null"  href="javascript:void(0)" (click)="toggelVolume($event,false)"><i class="fa fa-volume-off"></i></a>
      <!-- <input #ramSelector name="ram" [attr.disabled]="!isplaying  ? 'disabled' : null" type="range" id="range" min="0" max="100" (click)="setdefault($event)" (change)="setRam($event , ramSelector.value)" value="30"> -->

      <mat-slider   [disabled]="!isplaying  ? true : false" id="range" min="0.01" max="100" step="1"   (input)="onSlide($event)" value="30" [(ngModel)]="ngvolume" ></mat-slider>
  
  </div>
</section>
<div class="modal1 customModal errorView" style="display: none;" id="openinApp">
  <div class="modal-dialog" style="padding: 20px;">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <!-- <button type="button" class="close" (click)="dismissModal()">&times;</button> -->
      <p style="margin: 0;">This type of Radio stream is only playable on smartphones.</p>
      <div class="btn-group">
        <a href="{{activetrack?.sharelink}}" class="btn btn-primary" target="_blank">Get APP</a>
        <button  type="button" (click)="dismissModal1()" class="btn btn-primary getApp">OK</button>
      </div>
  </div>
</div>
<div class="modal1 customModal errorView" style="display: none;" id="androidpop">
  <div class="modal-dialog" style="padding: 20px;">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <!-- <button type="button" class="close" (click)="dismissModal()">&times;</button> -->
      <p style="margin: 0;">This type of Radio stream is only playable on Anrdoid App.</p>
      <div class="btn-group">
        <a href="{{activetrack?.sharelink}}" class="btn btn-primary" target="_blank">Get APP</a>
        <button  type="button" (click)="dismissModal1()" class="btn btn-primary getApp">OK</button>
      </div>
  </div>
</div>

 <ng4-loading-spinner  [timeout]="10000000000000000" [template]="template" [zIndex]="0"></ng4-loading-spinner> 
<router-outlet></router-outlet>