import { Component, Inject,OnInit,PLATFORM_ID } from '@angular/core';
import { CommonService } from '../common.service';
import { isPlatformBrowser } from '@angular/common'; 
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  list;
  user =  null;
  darkScrollbarOptions:any = { axis: 'y', theme: 'dark' };
  constructor(@Inject(PLATFORM_ID) private platformId: any ,private commonService:CommonService) { }
  

  showAppModal(){
    $("#appDownloadModal").fadeIn(); 
    $("app-sidebar").removeClass("open");
  }
  ngOnInit() {
    this.commonService.currentisLogin.subscribe(state =>  {
      // console.log('qqqqstate',state);
      if(state) {
        this.user =  JSON.parse(sessionStorage.getItem('user')) || [];
      } else {
        this.user = [];
      }
    });
    this.list = [
      'Home',
      'Near-Me',
      'Search',
      ' About Us',
      'Read about javascript'
   ];
   if (isPlatformBrowser(this.platformId)) {
this.user =  JSON.parse(sessionStorage.getItem('user')) || [];
   }
  }

}
