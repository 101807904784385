 import { Component, Inject , OnInit , PLATFORM_ID } from '@angular/core';
  import  { AuthenticationService } from '../home/authentication.service';
  import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
  import { Title, Meta } from '@angular/platform-browser';
  import { HttpClient } from '@angular/common/http';
  import { DOCUMENT } from '@angular/common';
  
  @Component({
    selector: 'app-mini-game',
    templateUrl: './mini-game.component.html',
    styleUrls: ['./mini-game.component.scss']
  })
  export class MiniGameComponent implements OnInit {
    game:any = [];
    constructor(private authenticationService : AuthenticationService,private SpinnerService: Ng4LoadingSpinnerService,private http: HttpClient,private titleService: Title, private metaService: Meta ,@Inject(DOCUMENT) private doc,  ) { }
  
    ngOnInit() {
      this.doc.body.classList.add('miniGamesView');
      const title = 'Play Mini Games - OneStop Radio';
      const desc = 'Get entertained with mini game like Rolling Panda, Basket Champ, Break The Rock and much more on your smartphone just for free!';
      
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      const url = this.doc.URL.replace(/^http:\/\//i, 'https://');
      link.setAttribute('href', url);
    
      this.titleService.setTitle(title);
       this.metaService.addTags([
       {name: 'description', content: desc},
     ]);
      this.getdetail();
    }
    getdetail(){
     // this.SpinnerService.show();
      // this.http.get('').subscribe((res) => {
      //   //this.jsonDataResult = res;
      //   console.log('--- result :: ',  res);
      // });
       this.authenticationService.getgame().subscribe((res) => {
    
       this.game = res.games;
      // this.game = data;
      this.game = this.game.map((e) => {
        return {
          Image : e.Image,
          categories :  e.categories,
          id :   e.id,
          name:  e.name,
          url :  e.url,
          slug:  e.name.replace(/ /g, "-").toLowerCase()
        }
      });
      console.log("this.game",this.game);
        this.SpinnerService.hide();
      },(error) => {
      
        this.SpinnerService.hide();
      });
    
    
     }
  }
  