import { Observable } from 'rxjs'; // only need to import from rxjs
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HomeService {
    constructor(http, _apiBase) {
        this.http = http;
        this._apiBase = _apiBase;
    }
    search(search) {
        return this.http.post(this._apiBase + 'search.php', { srch: search });
    }
    nearby(data) {
        return this.http.post(this._apiBase + 'web_nearby_station_list.php', data);
    }
    list() {
        return this.http.post(this._apiBase + 'web_homepage_data.php', { 'cc': 'US' });
    }
    stationDetail(st_name) {
        return this.http.post(this._apiBase + 'web_get_st_info.php', { 'slug': st_name });
    }
    streamLink(stream_link) {
        return this.http.get(this._apiBase + 'read-file.php?stream_link=' + stream_link);
    }
    streamData(stream_link) {
        return this.http.get('https://rfmdir.com/stream_data.php?stream=' + stream_link);
    }
    getcurrentlatlong() {
        return this.http.get(this._apiBase + 'web_latlong.php');
    }
    reco(data) {
        return this.http.post(this._apiBase + 'web_ml_reco.php', data);
    }
}
HomeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeService_Factory() { return new HomeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("apiBase")); }, token: HomeService, providedIn: "root" });
