import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  constructor(public http: HttpClient) {
   

   
  }

  private activeTrack = new BehaviorSubject(null);
  currentTrack = this.activeTrack.asObservable();

  changeCurrentTrack(track) {
    this.activeTrack.next(track)
  }
  
  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();
  changeMessage(message) {
    this.messageSource.next(message)
  }

  private isloading = new BehaviorSubject(true);
  currentLoader = this.isloading.asObservable();

  setLoader(state) {
    this.isloading.next(state)
  } 

  private isDisabed = new BehaviorSubject(true);
  currentDisabled = this.isDisabed.asObservable();

  setDisabled(state) {
    this.isDisabed.next(state)
  } 

  private isLogin = new BehaviorSubject(false);
  currentisLogin = this.isLogin.asObservable();

  setisLogin(state) {
   // console.log(' self.commonService.setLoader(false);',state);
    this.isLogin.next(state)
  } 

  private isfav = new BehaviorSubject(false);
  currentisFav = this.isfav.asObservable();

  setisfav(state) {
    
    this.isfav.next(state)
  } 
  
  private isplayerror = new BehaviorSubject(false);
  currentiserror = this.isplayerror.asObservable();

  setisplayerror(state) {
   this.isplayerror.next(state)
  } 

  private ispageload = new BehaviorSubject(false);
  currentispageload = this.ispageload.asObservable();

  setispageload(state) {
   this.ispageload.next(state)
  } 
  
  private isplay = new BehaviorSubject(false);
  currentisplay = this.isplay.asObservable();

  setisplay(state) {
   this.isplay.next(state)
  } 

  private isend = new BehaviorSubject(false);
  currentisend = this.isend.asObservable();

  setisend(state) {
   this.isend.next(state)
  } 
  private isvolume = new BehaviorSubject(0);
  currentvolume = this.isvolume.asObservable();

  setvolume(state) {
   this.isvolume.next(state)
  }

  private isbvolume = new BehaviorSubject(0);
  currentbvolume = this.isbvolume.asObservable();

  setbvolume(state) {
   this.isbvolume.next(state)
  }
  private isstart = new BehaviorSubject(0);
  currentStart = this.isstart.asObservable();

  setcurrentStart(state) {
   this.isstart.next(state)
  }
  private ischeckfav = new BehaviorSubject(false);
  currentiscFav = this.ischeckfav.asObservable();

  setiscfav(state) {
    
    this.ischeckfav.next(state)
  } 
  private issearchblank = new BehaviorSubject(false);
  currentissearch = this.issearchblank.asObservable();

  setissearchstate(state) {
    
    this.issearchblank.next(state)
  } 
  private nextplaydata = new BehaviorSubject({});
  currentnextplay= this.nextplaydata.asObservable();

  setcurrentnextplay(state) {
    
    this.nextplaydata.next(state)
  } 
  private isnext= new BehaviorSubject(false);
  currentisnext = this.isnext.asObservable();

  setisnext(state) {
   this.isnext.next(state)
  } 
  private isprevNextplaying= new BehaviorSubject(false);
  currentisprevNextplaying = this.isprevNextplaying.asObservable();

  setisprevNextplaying(state) {
   this.isprevNextplaying.next(state)
  }
  private isstartplaying= new BehaviorSubject(null);
  currentisstartplaying= this.isstartplaying.asObservable();

  setisstartplaying(state) {
   this.isstartplaying.next(state)
  }
  private issearch= new BehaviorSubject(null);
  currentisssearch= this.issearch.asObservable();

  setissearch(state) {
   this.issearch.next(state)
  } 
}
