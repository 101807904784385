/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "../home/authentication.service";
import * as i7 from "ngx-toastr";
import * as i8 from "ng4-loading-spinner";
import * as i9 from "@angular/common";
import * as i10 from "../common.service";
import * as i11 from "../home/home.service";
import * as i12 from "../sidebar/sidebar.component.ngfactory";
import * as i13 from "../sidebar/sidebar.component";
import * as i14 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i1.PLATFORM_ID, i4.Router, i5.FormBuilder, i6.AuthenticationService, i7.ToastrService, i8.Ng4LoadingSpinnerService, i9.Location, i10.CommonService, i11.HomeService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "section", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-sidebar", [], null, null, null, i12.View_SidebarComponent_0, i12.RenderType_SidebarComponent)), i1.ɵdid(4, 114688, null, 0, i13.SidebarComponent, [i1.PLATFORM_ID, i10.CommonService], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "footer", [], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i14.LayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i14.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
