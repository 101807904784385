import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs'; // only need to import from rxjs
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private http: HttpClient, @Inject('apiBase') private _apiBase: string) { }


  search(search): Observable<any> {
		return this.http.post(this._apiBase + 'search.php',{srch:search});
  }
  nearby(data): Observable<any> {
    return this.http.post(this._apiBase + 'web_nearby_station_list.php' ,data);
    
  }
  list(): Observable<any> {
    return this.http.post(this._apiBase + 'web_homepage_data.php',{'cc':'US'});
  } 

  stationDetail(st_name ){
    return this.http.post(this._apiBase + 'web_get_st_info.php',{'slug':st_name});
  }

  streamLink(stream_link ){
    return this.http.get(this._apiBase + 'read-file.php?stream_link='+ stream_link);
  }


  streamData(stream_link):Observable<any> {
    return this.http.get('https://rfmdir.com/stream_data.php?stream='+stream_link);
  }
  getcurrentlatlong():Observable<any> {
    return this.http.get(this._apiBase + 'web_latlong.php');
  }

  reco(data):Observable<any> {
    return this.http.post(this._apiBase + 'web_ml_reco.php',data);
    
  }
}
