import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { FormBuilder, FormGroup, Validators ,AbstractControl } from '@angular/forms';
import {switchMap, debounceTime, tap, finalize} from 'rxjs/operators';
import { Router }  from "@angular/router";
import {AuthenticationService} from '../home/authentication.service';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {Location} from '@angular/common';
import { CommonService } from '../common.service';
import { Subscription } from 'rxjs';
import {HomeService} from '../home/home.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  registrationForm: FormGroup;
  forgetForm: FormGroup;
  serverErrors = [];
  country;
  user;
  loginForm: FormGroup;
  subscription: Subscription;
  selectedValue = '';
  usersForm: FormGroup;
  isLoading = false;
  isload = false;
  filteredUsers = [];
  nulluser = {
    "all":'all'
  };
  values;
  stationId;
  reloadpage = false;
  isMobile = false;
  filter;
  control = new FormControl('');
  streets: any = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets: Observable<string[]>;
  constructor(@Inject(PLATFORM_ID) private platformId: any ,public router:Router,private formBuilder: FormBuilder, private authenticationService: AuthenticationService,private toastr:ToastrService,private SpinnerService: Ng4LoadingSpinnerService ,private _location: Location,private CommonService: CommonService ,public homeService:HomeService ) {
    if (isPlatformBrowser(this.platformId)) {
     
    
        this.isMobile = $(window).width() <= 500;
     
    this.user =  JSON.parse(sessionStorage.getItem('user')) || null;
    }
        this.subscription = this.CommonService.currentisLogin.subscribe(islogin =>  {
        if(islogin){
          if (isPlatformBrowser(this.platformId)) {
            $("#loginModal").fadeIn();
          //this.router.navigate(['/login']);
          }
        } else {
          if (isPlatformBrowser(this.platformId)) {
            this.user =  JSON.parse(sessionStorage.getItem('user')) || null;
            }
        }
        });
        this.subscription = this.CommonService.currentissearch.subscribe(state =>  {
          if(state){
            this.usersForm.patchValue({
              userInput: null 
            });
            this.reloadpage = true;
          }
          
          });
   }
   showAppModal1(){
     $("#appDownloadModal").fadeIn(); 
   }
   dismissAppModal(){
     $("#getAppModal").fadeOut(); 
   }
   showLoginDropdown(){
    $("#signInDrop").toggle();
  }
  resetPassword(){
    this.registrationForm.reset();
    this.loginForm.reset();
    this.forgetForm.reset();
    $("#loginModal").fadeOut();
    $("#resetModal").fadeIn();
  }
  showLoginModal(){
    this.registrationForm.reset();
    this.loginForm.reset();
    this.forgetForm.reset();
    $("#loginModal").fadeIn(); 
    $("#signUpModal").fadeOut(); 
  }
  showSignUp(){
    this.registrationForm.reset();
    this.loginForm.reset();
    this.forgetForm.reset();
    $("#selectcountry option:eq(0)").prop("selected", true);
    $("#signUpModal").fadeIn(); 
    $("#loginModal").fadeOut();
  }
  dismissModal(){
    this.registrationForm.reset();
    this.loginForm.reset();
    this.forgetForm.reset();
    $(".modal").fadeOut(); 
  }
  dismissModal1(){
    $(".modal1").fadeOut(); 
  }
  ngOnInit() {
    this.getcountry();
    if (isPlatformBrowser(this.platformId)) {
        //this.checkCookie();
    }
    this.forgetForm = this.formBuilder.group({
      user_email: ['',[Validators.required , Validators.email]],
      
     });
     this.loginForm = this.formBuilder.group({
      user_email: ['',[Validators.required , Validators.email]],
      user_password: ['', [Validators.required, Validators.minLength(6)]],
     });
    this.registrationForm = this.formBuilder.group({
      user_fname: ['',[Validators.required , Validators.minLength(3)]],
      user_lname: ['',[Validators.required , Validators.minLength(3)]],
      user_email: [null,[Validators.required , Validators.email]],
      user_password: ['', [Validators.required, Validators.minLength(6)]],
      user_Cpassword: ['', [Validators.required, Validators.minLength(6)]],
     user_country: ['', [Validators.required]],
    
    },{
      validator: [this.passwordConfirm],
    }
    );
    this.usersForm = this.formBuilder.group({
      userInput: null,
    });

      this.usersForm
      .get('userInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap((value) => this.homeService.search(value)
        .pipe(
          finalize(() => {
             this.isLoading = false ,this.selectedValue = value;
           
          }
          ),
          )
        )
      )
      .subscribe((res) => {
        this.filteredUsers= res.response.data;
      }, (error) => {
     });
     const self = this;
     this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''),self.registrationForm.patchValue({
        user_country:null
      })),
    );

  }
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    this.registrationForm.patchValue({
      user_country: ''
    })
    return this.streets.filter(street=> this._normalizeValue(street.c_name).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  private passwordConfirm(c: AbstractControl): { invalid: boolean } {
    if (c.get('user_password').value === c.get('user_Cpassword').value || c.get('user_Cpassword').pristine) {
      return null;
        }
    return { invalid: true };
  }
  onRgistartionSubmit(){
    
     for (let c in this.registrationForm.controls) {
       this.registrationForm.controls[c].markAsTouched();
   }
   
   if (this.registrationForm.valid) {
        
       
         
            this.SpinnerService.show();
       
           
            const data = this.registrationForm.value;
            delete data['user_Cpassword'];
          
            this.authenticationService.signup(data).subscribe((response) => {
            this.SpinnerService.hide();
               
               
                if (isPlatformBrowser(this.platformId)) {
                  if(response.data){
                    
                this.registrationForm.reset();
                this.toastr.success("User Successfully register.Please login");
                    this.showLoginModal();
                  } else {
                    this.toastr.error(response.http_response_message);
                  }
                  
                 //this.router.navigate(['/login']);
                }
            }, (error) => {
                this.SpinnerService.hide();
                if(error.error.http_response_code === 422){
                  this.toastr.error(error.error.http_response_message);
                  return false;
                }
                if (error.message === 'Http failure response for (unknown url): 0 Unknown Error') {
                    this.toastr.error('Something went wrong');
                    return;
        }
                if(error.error.errors) {
                    for(var i in error.error.errors) {
                        this.serverErrors.push([error.error.errors [i].msg]);
                    }
                    return;
                }
                this.toastr.error(error.error.message);
            });
          }
   }
   get f() { return this.loginForm.controls; }

    onSubmit(): void {
      this.serverErrors = [];
     for (let c in this.loginForm.controls) {
         this.loginForm.controls[c].markAsTouched();
     }
   
     if (this.loginForm.valid) {
         this.SpinnerService.show();
         this.authenticationService.login(this.loginForm.value).subscribe((response) => {
          
          
           if (isPlatformBrowser(this.platformId)) {
             if(response.data) {
              sessionStorage.setItem('user', JSON.stringify(response.data));
              this.user = sessionStorage.getItem('user');
              this.toastr.success('Login Sucessfully');
              this.CommonService.setisLogin(true);
              this.CommonService.setiscfav(true);
              $("#loginModal").fadeOut(); 
          }else {
            this.toastr.error(response.http_response_message);
            this.CommonService.setisLogin(false);

          }
          this.SpinnerService.hide();
           }
             this.authenticationService.favData(response.data.user_id).subscribe((data) => {
              // console.log('afterlogin');
               sessionStorage.setItem('favdata', JSON.stringify(data.data));
              
               this.SpinnerService.hide();
             } ,(error) => {
               this.SpinnerService.hide();
             });
             this.loginForm.reset();
             //this.CommonService.setisLogin(false);
            // this._location.back();
         }, (error) => {
          this.SpinnerService.hide();
          if (isPlatformBrowser(this.platformId)) {
              sessionStorage.removeItem('user');
             sessionStorage.removeItem('favdata');
          }
             if(error.error.http_response_code === 401){
               this.toastr.error(error.error.http_response_message);
               return false;
             }
             if (error.message === 'Http failure response for (unknown url): 0 Unknown Error') {
                 this.toastr.error('Something went wrong');
                 return;
     }
             if(error.error.errors) {
                 for(var i in error.error.errors) {
                     this.serverErrors.push([error.error.errors [i].msg]);
                 }
                 return;
             }
             this.toastr.error(error.error.message);
         });
     }
   }
   getcountry(){
    this.authenticationService.country().subscribe((res) => {
      this.country = res.data;
      this.streets = res.data;
      //("country",this.country);
   
     });
  }

  selectedclient(event) {
    const self = this;
   // console.log('event',   event.option.value);
    if(!event.option.value){
     // this.router.navigate(['search'],{ queryParams: { 'srch':  this.selectedValue} });
    }else if(event.option.value.all == 'all'){
      this.router.navigate(['search'],{ queryParams: { 'srch':  this.selectedValue} });
    } else {
      var newurl = event.option.value.slug;
      this.router.navigateByUrl('/', {skipLocationChange: true})
  .then(()=>this.router.navigate([newurl]));
      // self.router.onSameUrlNavigation = 'reload';
      // self.router.navigateByUrl(newurl).then(() => {
      
      //   self.router.onSameUrlNavigation = 'ignore';
      //  // self.contsct();
      //   //self.ngOn();
      // });
    }
    this.usersForm.patchValue({
      userInput: null 
    });
    this.selectedValue = '';
    this.filteredUsers = [];
    this.values = '';
  }
  selectedclient1(event) {
    //console.log("event",event);
    if(event.option.value){
      this.registrationForm.patchValue({
        user_country: event.option.value 
      });
    } else {
      this.registrationForm.patchValue({
        user_country:null
      });

    }
    
  }
  getOptionText(option) {
    if(option){
      return option.name;
    }
    return null;
  }
  getOptionText1(option) {
    if(option){
      return option.c_name;
    }
    return null;
  }
  onForgetSubmit(){
    this.serverErrors = [];
     for (let c in this.forgetForm.controls) {
       this.forgetForm.controls[c].markAsTouched();
   }
   
   if (this.forgetForm.valid) {
       this.SpinnerService.show();
       //this.registrationForm.removeControl('user_Cpassword');
      
     // var email = this.forgetForm.get('user_email');
       this.authenticationService.forgetPasswd(this.forgetForm.value).subscribe((response) => {
          this.SpinnerService.hide();
           this.toastr.success('Password reset link has been sent to your registered Email. Check to reset your password');
          
           this.forgetForm.reset();
           if (isPlatformBrowser(this.platformId)) {
            $("#resetModal").fadeIn();
           }
       }, (error) => {
           this.SpinnerService.hide();
           if(error.error.http_response_code === 422){
             this.toastr.error(error.error.http_response_message);
             return false;
           }
           if (error.message === 'Http failure response for (unknown url): 0 Unknown Error') {
               this.toastr.error('Something went wrong');
               return;
   }
           if(error.error.errors) {
               for(var i in error.error.errors) {
                   this.serverErrors.push([error.error.errors [i].msg]);
               }
               return;
           }
           this.toastr.error(error.error.message);
       });
   }
   } 
   isfavourite(){
    var favdata = JSON.parse(sessionStorage.getItem('favdata')) || [];
    const result = favdata.filter(x => x.id ===  this.stationId);
    if(result.length > 0) {
       // this.isfav =  true;
        this.CommonService.setisfav(true);
    } else {
      //this.isfav =  false;
      this.CommonService.setisfav(false);
    }
  }


  logout(){
    this.SpinnerService.show();
    this.CommonService.setisfav(false);
    setTimeout(() => {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('favdata');
      this.CommonService.setisLogin(false);
      this.SpinnerService.hide();
    }, 1000);
  }
  setCookie(name, value, daysToLive) {
    // Encode value in order to escape semicolons, commas, and whitespace
    var cookie = name + "=" + encodeURIComponent(value);
    
    if(typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires
        after the specified number of days */
        cookie += "; max-age=" + (daysToLive*24*60*60);
        
        document.cookie = cookie;
    }
}
 getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");
  
  // Loop through the array elements
  for(var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      
      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if(name == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
      }
  }
  
  // Return null if not found
  return null;
}
onfilterChange(){
  //
}
checkCookie() {
  var today = new Date();
  var day = today.getDay();
  var daylist = ["Sunday","Monday","Tuesday","Wednesday ","Thursday","Friday","Saturday"];
 // console.log("Today is : " + daylist[day] + ".");
  // Get cookie using our custom function
  var showdownloadpopup = this.getCookie("showdownloadpopup");
  var showyoutubepopup = this.getCookie("showyoutubepopup");
  //console.log("showyoutubepopup",showyoutubepopup);
  //console.log("showdownloadpopup",showdownloadpopup);

 
   if(showdownloadpopup == null && this.isMobile) {
  
      $("#getAppModal").fadeIn();
   
      this.setCookie("showdownloadpopup", "showdownloadpopup", 7);
  } else if(daylist[day] ==  'Friday' && showyoutubepopup == null ){
    $("#videoModal").fadeIn();
   
    this.setCookie("showyoutubepopup", "showyoutubepopup", 7);
  }

}
  
}
