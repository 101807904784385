import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';

interface login {
	user_email: string;
	user_password: string;
}
interface loginResponse {
	message: string;
	status: number;
	data: Object;
}

@Injectable({
	providedIn: 'root'
  })
export class AuthenticationService {

	constructor(private http: HttpClient, @Inject('apiBase') private _apiBase: string) { }

	/** 
	 * to check autentication of user
	 * 
	 * @param none
	 * @return observable of Observable 
	*/
	authenticated(): Observable<any> {
		// return Observable.of({authenticated: true});
		if (sessionStorage.getItem('user')) {
			return of({authenticated: true});
			//return Observable.of({authenticated: true});
			
		} else {
			return of({authenticated: false});
			//return Observable.of({authenticated: false});
		}
	}
	/** 
	 * to check autentication of user
	 * 
	 * @param none
	 * @return observable of Observable 
	*/
	login(data: login): Observable<any> {
		return this.http.post<any>(this._apiBase + 'user_signin.php', data);
	}
	loginwithsocial(data: login): Observable<any> {
		return this.http.post<any>(this._apiBase + 'social_user.php', data);
	}
	signup(data): Observable<any> {
		return this.http.post<any>(this._apiBase + 'user.php', data);
	}
	favData(user_id){
		return this.http.get<any>(this._apiBase + 'user_favorites.php?user_id='+ user_id);
	}
	updateProfile(data): Observable<any> {
		return this.http.put<any>(this._apiBase + 'user.php', data);
	} 
	forgetPasswd(data): Observable<any> {
		return this.http.post<any>(this._apiBase + 'forget_password.php', data);
	} 
	updatePassword(data): Observable<any> {
		return this.http.post<any>(this._apiBase + 'user_passchange.php', data);
	}
	resetPassword(data): Observable<any> {
		return this.http.post<any>(this._apiBase + 'user_auth.php', data);
	}
	country():Observable<any> {
		return this.http.get(this._apiBase + 'country.php');
	   
	  }
	  countrylist():Observable<any> {
		return this.http.post(this._apiBase + 'web_country_list.php',{});
	  }
	contactus(data):Observable<any> {
		return this.http.post(this._apiBase + 'web_contact_us.php' , data);
	   
	}
	suggeststation(data):Observable<any> {
		return this.http.post(this._apiBase + 'web_suggest_station.php' , data);
	   
	} 

	faqlist():Observable<any> {
		return this.http.get(this._apiBase + 'faqlist.php');
	    
	} 
	faqdetail(slug):Observable<any> {
		return this.http.get(this._apiBase + 'full_faq.php?slug='+slug);
	    
	}
	
	// genredetail(slug):Observable<any> {
	// 	return this.http.get(this._apiBase + 'genre_desc.php?genre_name='+slug);
	    
	//}
	faqsearch(slug):Observable<any> {
		return this.http.get(this._apiBase + 'faqlist_search.php?search='+slug);
	    
	}
	faqsub(f_id,status):Observable<any> {
		return this.http.post(this._apiBase + 'faq_response.php',{'f_id':f_id,'status':status});
	    
	}

	bloglist():Observable<any> {
		return this.http.get(this._apiBase + 'bloglist.php');
	}
	blogdetail(slug):Observable<any> {
		return this.http.get(this._apiBase + 'full_blog.php?slug='+slug)
	}
	newsletter(data):Observable<any> {
		return this.http.post(this._apiBase + 'newsletter.php' , data);
	   
}
test(slug):Observable<any> {
	return this.http.get(this._apiBase + 'testing.php?slug='+slug);
	
}
getmetadetail(slug):Observable<any> {
	return this.http.get(this._apiBase + 'get_meta_detail.php?slug='+slug);
	
}
blogpost(slug):Observable<any> {
	return this.http.get(this._apiBase + 'blog_subscribe.php?email='+slug);
	
}
getgame():Observable<any> {
	return this.http.get('https://theonestopradio.com/api/games.php');
	
}
getwebgame():Observable<any> {
	return this.http.get('https://theonestopradio.com/api/web_game.php');
	
}
getrecord(data):Observable<any> {
	return this.http.post('https://theonestopradio.com/api/game_record.php',data);
	
}

}


