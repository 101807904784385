/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mini-game.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./mini-game.component";
import * as i5 from "../home/authentication.service";
import * as i6 from "ng4-loading-spinner";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/platform-browser";
var styles_MiniGameComponent = [i0.styles];
var RenderType_MiniGameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MiniGameComponent, data: {} });
export { RenderType_MiniGameComponent as RenderType_MiniGameComponent };
function View_MiniGameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "gameList"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "thumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, ("/minigame/" + _v.context.$implicit.slug)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.Image, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.Image, ""); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.categories; _ck(_v, 8, 0, currVal_4); }); }
export function View_MiniGameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container gamesView"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "header", [["class", "panelHead"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "h4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Games"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "panelBody gamesList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MiniGameComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.game; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_MiniGameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mini-game", [], null, null, null, View_MiniGameComponent_0, RenderType_MiniGameComponent)), i1.ɵdid(1, 114688, null, 0, i4.MiniGameComponent, [i5.AuthenticationService, i6.Ng4LoadingSpinnerService, i7.HttpClient, i8.Title, i8.Meta, i3.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MiniGameComponentNgFactory = i1.ɵccf("app-mini-game", i4.MiniGameComponent, View_MiniGameComponent_Host_0, {}, {}, []);
export { MiniGameComponentNgFactory as MiniGameComponentNgFactory };
