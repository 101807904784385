
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {Injectable , Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';  
@Injectable()

export class httpInterceptor implements HttpInterceptor {
	constructor(@Inject(PLATFORM_ID) private platformId: any  , private router: Router,
		) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers: any;
		headers = request.headers;

		
			//headers = headers.append('Content-Type', 'application/json');
			//headers = headers.append('Accept', 'application/json');
			 headers = headers.append('Authorization', 'Bearer snqgcgkhgzqwlipzfghbbxdpwryeryxu');

		// clone request and replace 'http://' with 'https://' at the same time
		//headers = headers.append('Authorization', 'Bearer GfTDVBTdgvbTYhBDGnMHYDGBfTRTFGHB');

	
		///headers = headers.append('Accept', 'application/json');
		// headers = headers.append('Content-Type', 'application/json');
		var browserLang = 'en';
		if (isPlatformBrowser(this.platformId)) {
		if (localStorage.getItem('locale')) {  
			 browserLang = localStorage.getItem('locale');  
		}
	   }
	
		//console.log('headers', headers);
		var  requestClone = request.clone({ headers });
		if (requestClone.method.toLowerCase() === 'post') {
			requestClone =  requestClone.clone({
					body: {...requestClone.body, 'X-Language':browserLang}
				  });
			// if (requestClone.body instanceof FormData) {
			// 	requestClone =  requestClone.clone({
			// 	body: requestClone.body.append(tokenName, tokenToAdd)
			//   })
			// } else {
			//   const foo = {}; foo[tokenName] = tokenToAdd;
			//   req =  req.clone({
			// 	body: {...req.body, ...foo}
			//   })
			// }            
		  } 
		  if (requestClone.method.toLowerCase() === 'get') {
			requestClone = requestClone.clone({
			  params: requestClone.params.set('X-Language',browserLang)
			});
		  } 
		return next.handle(requestClone);
		
	
}

	
}