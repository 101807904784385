<header class="mainHeader">
    <a href="javascript:void(0)" class="sidebarToggle"><i class="fa fa-bars"></i></a>
    <a tabindex="0" class="brandLogo"[routerLink]="['/']"><img width="180" height="46" alt="OneStop Radio logo" src="https://onimgspace.s3.ap-south-1.amazonaws.com/assets/Logo.png"></a>
    <form *ngIf="!router.url.includes('search') && !router.url.includes('404') && !router.url.includes('faq')" class="mainSearch"  [formGroup]='usersForm'>
      <input  class="form-control" matInput  placeholder="What would you like to listen?"  class="gettags" [matAutocomplete]="auto" formControlName='userInput'>
    <!-- <mat-form-field class="example-full-width">
    </mat-form-field> -->
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedclient($event)" [displayWith]="getOptionText">
     
      <mat-option *ngIf="isLoading" class="is-loading"></mat-option>
      <ng-container  *ngIf="!isLoading">
        <mat-option class="noResult" *ngIf="(filteredUsers.length === 0) &&  (selectedValue !== '') && (values !== '') && !reloadpage">
          <span>No results !  Try  </span>
          <span> <a routerLink="/search" [queryParams]="{srch:selectedValue}">    "Advance Search"</a></span> 
        </mat-option>
        <mat-option *ngFor="let user of filteredUsers" [value]="user">
          <span>{{ user.name }}</span>
          <small> {{user.genre}} </small>
        </mat-option>
        <mat-option [value]="nulluser" *ngIf="filteredUsers.length !== 0">
          <span class="search-view-more">View all</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </form>
    <div class="navBtn">
      <a class="btn3 fourth gameBtn" href="https://broadcaster.theonestopradio.com/" target="_blank"  ><i class="fa fa-microphone"></i> <label>Register Broadcaster</label><div class="border"></div><div class="border"></div><div class="border"></div><div class="border"></div></a>
        <a aria-label="Android App" class="btn3 fourth" href="https://play.google.com/store/apps/details?id=com.onestop.radio.app.fmradio.fm.am.worldfm"  target="_blank"><i class="fa fa-android"></i> <label>Get App</label><div class="border"></div><div class="border"></div><div class="border"></div><div class="border"></div></a>
        <div class="dropdownMenu" *ngIf="!user">
          <a class="btn3 getApp fourth" id="signDropdownMenu" (click)="showLoginDropdown()" href="javascript:void(0)"><i class="fa fa-sign-in"></i> <label>Sign In</label>&nbsp;<i class="fa fa-angle-down"></i><div class="border"></div><div class="border"></div><div class="border"></div><div class="border"></div></a>
          <ul id="signInDrop" style="display: none;">
            <li><a aria-label="Login" href="javascript:void(0)" class="btn3 getApp fourth" (click)="showLoginModal()">Sign In Listener</a></li>
            <li><a aria-label="Login" target="_blank" href="https://broadcaster.theonestopradio.com/login" class="btn3 getApp fourth">Sign In Brodcaster</a></li>
          </ul>
        </div>
        <a aria-label="Logout" style="background-color: #CE5C5C;color: #fff;" *ngIf="user" (click)="logout()" href="javascript:void(0)" class="btn3 fourth"><i class="fa fa-sign-out"></i> <label>Logout</label><div class="border"></div><div class="border"></div><div class="border"></div><div class="border"></div></a>
    </div>
</header>
<div class="modal customModal" style="display: none;" id="loginModal">
    <div class="modal-dialog">
        <button type="button" class="close" (click)="dismissModal()">&times;</button>
        <span class="h3"><img src="https://onimgspace.s3.ap-south-1.amazonaws.com/assets/default.png" alt="default">Globally No. 1 Radio Platform</span>
        <span class="h5">Over <span class="primarycolor">80K+</span> Radio Station hitting you with hits, every day!</span>
        <span class="h2">Sign in</span>
        <form  class="contact-form row" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group"><i class="fa fa-user"></i><input formControlName="user_email" type="text" placeholder="Email Id" class="form-control">
            <div *ngIf="f.user_email.errors" class="invalid-feedback">
                <div *ngIf="loginForm.controls['user_email'].hasError('required') && (loginForm.controls['user_email'].dirty || loginForm.controls['user_email'].touched)"><label>Email id is required</label></div>
                <div *ngIf="loginForm.controls['user_email'].hasError('email') && (loginForm.controls['user_email'].dirty || loginForm.controls['user_email'].touched)"><label>Please enter a valid Email address</label></div>
            </div>
        </div>
        <div class="form-group"><i class="fa fa-lock"></i><input formControlName="user_password"  type="password" placeholder="Password" class="form-control">
            <div *ngIf="f.user_password.errors" class="invalid-feedback">
                <div *ngIf="loginForm.controls['user_password'].hasError('required') && (loginForm.controls['user_password'].dirty || loginForm.controls['user_password'].touched)"><label>password field is required</label></div>
                <div *ngIf="loginForm.controls['user_password'].hasError('minlength') && (loginForm.controls['user_password'].dirty || loginForm.controls['user_password'].touched)"><label>password minimum length is 6</label></div>
            </div>
        
        </div>
        <a href="javascript:void()" (click)="resetPassword()">Forgot Password?</a>
        <button type="submit"  class="btn btn-primary">Sign in</button>
    </form>
        <p>Don't have an account? <a href="javascript:void(0)" (click)="showSignUp()">Sign Up</a></p>
    </div>
  </div>
<div class="modal customModal" style="display: none;" id="resetModal">
    <div class="modal-dialog">
        <button type="button" class="close" (click)="dismissModal()">&times;</button>
        <span class="h3"><img src="https://onimgspace.s3.ap-south-1.amazonaws.com/assets/default.png" alt="default">Globally No. 1 Radio Platform</span>
        <!-- <span class="h5">Over <span class="primarycolor">80K+</span> Radio Station hitting you with hits, every day!</span> -->
        <p>Don't worry! Just fill in your email and we will help you reset you password.</p>
        <form  class="contact-form row" [formGroup]="forgetForm" (ngSubmit)="onForgetSubmit()">
        <div class="form-group"><i class="fa fa-user"></i>
          <input type="Email" formControlName="user_email" placeholder="Email Id" class="form-control">
          <div *ngIf="forgetForm.controls['user_email'].errors" class="invalid-feedback">
            <div *ngIf="forgetForm.controls['user_email'].hasError('required') && (forgetForm.controls['user_email'].dirty || forgetForm.controls['user_email'].touched)"><label>Email id required</label></div>
            <div *ngIf="forgetForm.controls['user_email'].hasError('email') && (forgetForm.controls['user_email'].dirty || forgetForm.controls['user_email'].touched)"><label>Please enter a valid email id</label></div>
        </div>
        
        </div>
      
        <button  type="submit" class="btn btn-primary">Send Mail</button>
      </form>
    </div>
  </div>
  <div class="modal customModal" style="display: none;" id="signUpModal">
      <div class="modal-dialog">
          <button type="button" class="close" (click)="dismissModal()">&times;</button>
          <span class="h3"><img src="https://onimgspace.s3.ap-south-1.amazonaws.com/assets/default.png" alt="default"> Globally No. 1 Radio Platform </span>
          <span class="h5">Over <span class="primarycolor">80K+</span> Radio Station hitting you with hits, every day!</span>
          <span class="h2">Sign Up</span>
          <form  [formGroup]="registrationForm" (ngSubmit)="onRgistartionSubmit()">
          <div class="signup">
              <div class="form-group"><i class="fa fa-user"></i><input type="text" formControlName="user_fname" placeholder="First Name" class="form-control">
              <div *ngIf="registrationForm.controls['user_fname'].errors" class="invalid-feedback">
                    <div *ngIf="registrationForm.controls['user_fname'].hasError('required') && (registrationForm.controls['user_fname'].dirty || registrationForm.controls['user_fname'].touched)"><label>First name required.</label></div>
                    <div *ngIf="registrationForm.controls['user_fname'].hasError('minlength') && (registrationForm.controls['user_fname'].dirty || registrationForm.controls['user_fname'].touched)"><label>First name minlength 3.</label></div>
                </div>
            </div>
              <div class="form-group"><i class="fa fa-user"></i><input formControlName="user_lname"  type="text" placeholder="Last Name" class="form-control">
                <div *ngIf="registrationForm.controls['user_lname'].errors" class="invalid-feedback">
                    <div *ngIf="registrationForm.controls['user_lname'].hasError('required') && (registrationForm.controls['user_lname'].dirty || registrationForm.controls['user_lname'].touched)"><label>Last name required.</label></div>
                    <div *ngIf="registrationForm.controls['user_fname'].hasError('minlength') && (registrationForm.controls['user_lname'].dirty || registrationForm.controls['user_lname'].touched)"><label>Last name minlength 3.</label></div>
                </div>
            </div>
              <div class="form-group"><i class="fa fa-envelope"></i><input type="text" placeholder="Email Address" formControlName="user_email" class="form-control">
                <div *ngIf="registrationForm.controls['user_email'].errors" class="invalid-feedback">
                    <div *ngIf="registrationForm.controls['user_email'].hasError('required') && (registrationForm.controls['user_email'].dirty || registrationForm.controls['user_email'].touched)"><label>Email is required.</label></div>
                    <div *ngIf="registrationForm.controls['user_email'].hasError('email') && (registrationForm.controls['user_email'].dirty || registrationForm.controls['user_email'].touched)"><label>Please enter a valid email.</label></div>
                </div>
            </div>
              <div *ngIf="country" class="form-group countryDrop"><i class="fa fa-globe"></i>
                <input type="text" placeholder="Country" [formControl]="control" [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="selectedclient1($event)">
                    <mat-option *ngFor="let street of filteredStreets | async" [value]="street.c_name">
                      {{street.c_name}}
                    </mat-option>
                  </mat-autocomplete>
                  <div *ngIf="registrationForm.controls['user_country'].errors" class="invalid-feedback">
                    <div *ngIf="registrationForm.controls['user_country'].hasError('required') && (registrationForm.controls['user_country'].dirty || registrationForm.controls['user_country'].touched)"><label>Country field required.</label></div>
                  </div>
            </div>
              <div class="form-group"><i class="fa fa-lock"></i>
                <input type="password" placeholder="Password" formControlName="user_password"  class="form-control">
                <div *ngIf="registrationForm.controls['user_password'].errors" class="invalid-feedback">
                    <div *ngIf="registrationForm.controls['user_password'].hasError('required') && (registrationForm.controls['user_password'].dirty || registrationForm.controls['user_password'].touched)"><label>Password field is required.</label></div> 
                    <div *ngIf="registrationForm.controls['user_password'].hasError('minlength') && (registrationForm.controls['user_password'].dirty || registrationForm.controls['user_password'].touched)"><label>Password minlength is 6.</label></div>
                  
                  </div>
            </div>
              <div class="form-group"><i class="fa fa-lock"></i><input type="password" placeholder="Confirm Password" formControlName="user_Cpassword" class="form-control">
                <div *ngIf="registrationForm.controls['user_Cpassword'].errors" class="invalid-feedback">
                    <div *ngIf="registrationForm.controls['user_Cpassword'].hasError('required') && (registrationForm.controls['user_Cpassword'].dirty || registrationForm.controls['user_Cpassword'].touched)"><label>Confirm Password field is required</label></div>
      
                  </div>
                  <div *ngIf="registrationForm.errors"  class="invalid-feedback">
                    <div *ngIf="registrationForm.errors.invalid"><label>Password and Confirm password should be same.</label></div>
                   </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary">Sign Up</button>
          </form>
          <p>Already have an account? <a (click)="showLoginModal()" href="javascript:void(0)">Login</a></p>
      </div>
    </div>
    <div class="modal1 customModal errorView" style="display: none;" id="errorplay">
      <div class="modal-dialog" style="padding: 20px;">
        <i class="fa fa-play-circle" aria-hidden="true"></i>
          <!-- <button type="button" class="close" (click)="dismissModal()">&times;</button> -->
          <p style="margin: 0;">Radio stream is down, please try after sometime</p>
          <div class="btn-group">
            <a href="https://theonestopradio.com/getapp" target="_blank" class="btn btn-primary" >Get APP</a>
            <button  type="button" (click)="dismissModal1()" class="btn btn-primary getApp">OK</button>
          </div>
      </div>
    </div>

    

    <div class="modal customModal appDownloadModal" style="display: none;" id="appDownloadModal">
      <div class="modal-dialog">
        <button type="button" class="close" (click)="dismissAppModal()">&times;</button>
        <p class="text">Coming Soon</p>
        <button (click)="dismissAppModal()" class="btn btn-primary">Ok</button>
      </div>
    </div>

    <!-- <div class="modal customModal getAppModal" id="getAppModal" style="display: none;">
      <div class="modal-dialog">
        <button type="button" class="close" (click)="dismissModal()">&times;</button>
        <img src="../../assets/images/default_old.png" alt="default">
        <span class="h2">OneStop radio is an internet-based radio platform to tune in to your favorite FM radio anywhere & anytime across the globe.</span>
        <p class="text"><span>Without paying anything!</span></p>
        <a href="https://theonestopradio.com/getapp" target="_blank"  class="btn btn-primary">Download</a>
      </div>
    </div> -->
    <!-- <div class="modal customModal videoModal"  style="display: none;" id="videoModal">
      <div class="modal-dialog">
        <button type="button" class="close" (click)="dismissModal()">&times;</button>
        <iframe src="https://www.youtube.com/embed/kQkmiwkIyK8"></iframe>
      </div>
    </div> -->
    <!-- <div class="modal1 customModal" style="display: none;" id="appdownload">
      <div class="modal-dialog" style="padding: 20px;">
        <!-- <i class="fa fa-play-circle" aria-hidden="true"></i> -->
          <!-- <button type="button" class="close" (click)="dismissModal()">&times;</button> -->
          <!-- <p>Want The Best Experience?<br>
            Get The Radio FM App. <br><br>Explore Radio Stations For Free!</p>
          <div class="btn-group">
            <button  type="button" class="btn btn-primary getApp">Get APP</button>
            <button  type="button" (click)="dismissModal1()" class="btn btn-primary">OK</button>
          </div>
      </div> -->
    <!-- </div> --> 

  