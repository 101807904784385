<a href="javascript:void(0)" class="closeSidebar">&times;</a>
<aside class="sidebar" id="myElementId1">
    <ul>
        <li><a [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-home"></i> Home</a></li>
        <li><a [routerLink]="['/nearby']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-map-marker"></i> Near-Me</a></li>
        <li><a [routerLink]="['/search']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-search"></i> Search</a></li>
        <li><a [routerLink]="['/about-us']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-info-circle"></i> About Us</a></li>
    </ul>
    <!-- <span class="title">Games</span>
    <ul>
        <li><a [routerLink]="['/game']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-gamepad"></i> Play Mini Games</a></li>
  
    </ul> -->
    <span class="title">Your Library</span>
    <ul>
        <li><a [routerLink]="['/recent']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-clock-o"></i> Recently Played</a></li>
        <li *ngIf="user.user_id"><a [routerLink]="['/favourite']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-heart-o"></i> Favorite Station</a></li>
    </ul>
    <span class="title">Support</span>
    <ul>
        <li><a [routerLink]="['/faqs']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-question-circle-o"></i> FAQ</a></li>
        <li><a [routerLink]="['/suggest-station']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-plus-circle"></i> Suggest a Station</a></li>
        <li><a [routerLink]="['/contact-us']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-phone"></i> Contact Us</a></li>
            <li><a [routerLink]="['/widget']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
                true}"><i class="fa fa-window-restore"></i> Widget</a></li>
        <li><a class="news" [routerLink]="['/blog']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-newspaper-o fa-flip-horizontal"></i> News & Blog</a></li>
        <li><a  [routerLink]="['/privacy-policy']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-file-text-o"></i> Privacy Policy</a></li>
        <li><a  [routerLink]="['/terms']"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-check-circle-o"></i> Terms & Conditions</a></li>
    </ul>
    <span class="title">Partners</span>
    <ul>
        <li><a href="https://broadcaster.theonestopradio.com/" target="_blank"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
            true}"><i class="fa fa-plus-circle"></i>Register Broadcaster</a></li>
            <li><a href="https://radio.co/" target="_blank"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
                true}"><i class="fa fa-plus-circle"></i> Create a station</a></li>
        
    </ul>
    <span class="title">Connect with us</span>

    <ul class="social">
        <li><a aria-label="Facebook" href="https://www.facebook.com/OneStopRadio" target="_blank"><i class="fa fa-facebook"></i></a></li>
        <li><a aria-label="Instagram" href="https://www.instagram.com/onestop_radio/" target="_blank"><i class="fa fa-instagram"></i></a></li>
        <li><a aria-label="Twitter" href="https://twitter.com/OneStop_Radio" target="_blank"><i class="fa fa-twitter"></i></a></li>
        <li><a aria-label="Youtube" href="https://www.youtube.com/channel/UCOqNoTLlqKIanku5eOprHNw" target="_blank"><i class="fa fa-youtube"></i></a></li>
        <li><a aria-label="Pinterest" href="https://www.pinterest.com/OneStop_Radio" target="_blank"><i class="fa fa-pinterest"></i></a></li>
    </ul>
    <a href="https://play.google.com/store/apps/details?id=com.onestop.radio.app.fmradio.fm.am.worldfm" class="androidDownload" target="_blank"><img alt="Android App" loading="lazy" src="https://onimgspace.s3.ap-south-1.amazonaws.com/assets/android.png"></a>
    <a href="https://www.amazon.in/OneStop-Radio-Enjoy-Fm/dp/B09YQGVVJ3/ref=nodl_?dplnkId=25abeae8-6df8-403e-82f6-8ca5e872c6c6" class="androidDownload" target="_blank"><img alt="Android App" loading="lazy" src="../../assets/images/amazon.png"></a>
    <a href="https://appgallery.huawei.com/app/C106228923" class="androidDownload" target="_blank"><img alt="Android App" loading="lazy" src="../../assets/images/huawei.png"></a>
</aside>