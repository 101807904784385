import { NgModule } from '@angular/core';
import { PreloadAllModules,NoPreloading, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { MiniGameComponent } from './mini-game/mini-game.component';
import { DetailComponent } from './mini-game/detail/detail.component';
const routes: Routes = [
    { 
    path: 'minigame', 
    component: MiniGameComponent,
 
  },
  { 
    path: 'minigame/:id', 
    component: DetailComponent,
 
  },
   { 
    path: '', 
    component: LayoutComponent,
 
    children: [
     { 
        path: '', 
        loadChildren: './home/home.module#HomeModule',
        //pathMatch: 'full',
      }, 
      
      { 
        path: 'radio', 
        loadChildren: './player/player.module#PlayerModule',
      }, 
      { 
        path: 'nearby', 
        loadChildren: './nearby/nearby.module#NearbyModule',
      },
      {
        path:"country",
        loadChildren: './country-list/country-list.module#CountryListModule',
      },
      { 
        path: 'country/:country_code', 
        loadChildren: './country-detail/country-detail.module#CountryDetailModule',
      },
      { 
        path: 'language', 
        loadChildren: './language-list/language-list.module#LanguageListModule',
     },
     { 
      path: 'language/:language_code', 
      loadChildren: './language-detail/language-detail.module#LanguageDetailModule',
    },
    { 
      path: 'genre', 
      loadChildren: './genre-list/genre-list.module#GenreListModule',
   },
   { 
    path: 'genre/:genre_code', 
    loadChildren: './genre-detail/genre-detail.module#GenreDetailModule',
  },
  { 
    path: 'network', 
    loadChildren: './network-list/network-list.module#NetworkListModule',
 },
 { 
  path: 'network/:network_code', 
  loadChildren: './network-detail/network-detail.module#NetworkDetailModule',
 },
 { 
  path: 'search', 
  loadChildren: './search/search.module#SearchModule',
 },
 { 
  path: 'recent', 
  loadChildren: './recent/recent.module#RecentModule',
 },
 { 
  path: 'favourite', 
  loadChildren: './favourite/favourite.module#FavouriteModule',
 },
 { 
  path: 'faqs', 
  loadChildren: './faq/faq.module#FaqModule',
 },
 { 
  path: 'faq/:faq', 
  loadChildren: './faq-detail/faq-detail.module#FaqDetailModule',
 },
 {
   path:'suggest-station',
   loadChildren: './suggest-station/suggest-station.module#SuggestStationModule',
 },
 {
  path:'contact-us',
  loadChildren: './contact-us/contact-us.module#ContactUsModule',
},
{
  path:'about-us',
  loadChildren: './about-us/about-us.module#AboutUsModule',
},
{
  path:'privacy-policy',
  loadChildren:'./privacy-policy/privacy-policy.module#PrivacyPolicyModule'
}, 
{
  path:'huawei',
  loadChildren:'./privacy-policy/privacy-policy.module#PrivacyPolicyModule'
},
{
  path:'blog',
  loadChildren: './blog/blog.module#BlogModule',
},
{
path:'terms',
loadChildren:'./terms/terms.module#TermsModule'
},
{
  path:'widget',
  loadChildren: './widgets/widgets.module#WidgetsModule',
},{
  path:'game',
  loadChildren:'./game/game.module#GameModule'
},
{
  path:'404',
  loadChildren: './notfound/notfound.module#NotfoundModule',
},
// { 

//     path: ':faq', 
//     redirectTo: '/404'
//  },

//  {
//     path: '**', 
//     redirectTo: '/404'
// },


 ]
},
];
@NgModule({
  imports: [
    [RouterModule.forRoot(routes)],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

