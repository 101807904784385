import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs'; // only need to import from rxjs
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient, @Inject('apiBase') private _apiBase: string) { }

  favourite(data): Observable<any> {
		return this.http.post<any>(this._apiBase + 'user_favorites.php', data);
  } 
  prevnext(data): Observable<any> {
    return this.http.post<any>(this._apiBase + 'next_previous_st.php', data);
   
  }
  prevnextsearch(data): Observable<any> {
    return this.http.post<any>(this._apiBase + 'next_previous_st.php', data);
   
  }

}
