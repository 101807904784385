 import { Component, OnInit ,  ViewChild, ElementRef,Inject} from '@angular/core';
  import { ActivatedRoute ,Router  } from '@angular/router';
  import  { AuthenticationService } from '../../home/authentication.service';
  import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
  import { DomSanitizer } from '@angular/platform-browser';
  import { HttpClient } from '@angular/common/http';
  import { Title, Meta } from '@angular/platform-browser';
  import { DOCUMENT } from '@angular/common';
  @Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
  })
  export class DetailComponent implements OnInit {
    @ViewChild('iframe', {static: false}) iframe: ElementRef;
  
     id = "";
     game = [];
     data;
     show = false;
     controllerSrc: any;
    constructor( private router: Router, private route: ActivatedRoute,private authenticationService : AuthenticationService,private SpinnerService: Ng4LoadingSpinnerService,private http: HttpClient,private sanitizer: DomSanitizer,private titleService: Title, private metaService: Meta ,@Inject(DOCUMENT) private doc, ) { }
  
    ngOnInit() {
      this.doc.body.classList.add('miniGamesView');
      if(this.route.snapshot.params.id) {
        this.id = this.route.snapshot.params.id;
        this.getdetail();
  
  
      
    }
  }
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  // ngAfterViewInit() {
  
  //   let doc =  this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
  
  // }
  getdetail(){
    this.SpinnerService.show();
    // this.http.get('').subscribe((res) => {
    //   //this.jsonDataResult = res;
    //   console.log('--- result :: ',  res);
    // });
     this.authenticationService.getgame().subscribe((res) => {
      console.log("resresres",res);
     this.game = res.games;
     this.game = this.game.map((e) => {
      return {
        Image : e.Image,
        categories :  e.categories,
        id :   e.id,
        name:  e.name,
        url :  e.url,
        slug:  e.name.replace(/ /g, "-").toLowerCase()
      }
    });
     this.data = this.game.filter(
      book => book.slug === this.id);
  console.log("book",this.data[0].url);
  if(this.data[0]) {
    const title = 'Play & Enjoy '+this.data[0].name+' - OneStop Radio';

    const desc = '  Experience the thrill of '+this.data[0].name+' on your smartphone with realistic graphics and easy-to-use controls.';
    
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    const url = this.doc.URL.replace(/^http:\/\//i, 'https://');
    link.setAttribute('href', url);
   
    this.titleService.setTitle(title);
     this.metaService.addTags([
     {name: 'description', content: desc},
   ]);
   
   this.authenticationService.getrecord({'game_name':this.data[0].name,"type": 'App'}).subscribe((res) => {
    console.log("resresres",res);

   });
  }
   this.controllerSrc = this.getSafeUrl(this.data[0].url+"&mute=1");
  
   //var iframe = document.querySelector('iframe[src="https://blastar-1984.appspot.com/"]');
  // This code could probably be tidied up, depending on how familiar you are with the game code
  
   this.show = true;
   setTimeout(() => {
    this.iframe.nativeElement.contentDocument.getElementById("muted").checked = true;
  this.iframe.nativeElement.contentWindow.speaker[0].muted = true
  this.iframe.nativeElement.contentWindow.speaker[1].muted = true
    //         var iframe = document.getElementById('iframeId');
    // var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    //         var vid = document.getElementById("myVideo");
    
    
    //   vid.muted = true;
    // const iframe: any = document.getElementById('myVideo');
  
    // iframe.setAttribute("volume", "0");
    // iframe.setAttribute("muted", "true");
  //   this.mutePage();
  //   var iframe:any = document.getElementById('myVideo');
  // var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
  // var MediaSource = innerDoc.getElementById('mediaElem');
  // MediaSource.muted=true;
          }, 3000);
      this.SpinnerService.hide();
    },(error) => {
    
      this.SpinnerService.hide();
    });
  
  
   }
   muteall(){
   // alert('ddd');
    // var iframe:any = $(this).prev('iframe')[0];
    // iframe.contentWindow.postMessage('{"method":"setVolume", "value":1}', '*');
    
   }
    muteMe(elem) {elem.muted = false;elem.pause();}
    mutePage() {
       var elems = document.querySelectorAll("video , audio");
   
       [].forEach.call(elems, function(elem) { this.muteMe(elem); });
   }
  }
  