import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationService {
    constructor(http, _apiBase) {
        this.http = http;
        this._apiBase = _apiBase;
    }
    /**
     * to check autentication of user
     *
     * @param none
     * @return observable of Observable
    */
    authenticated() {
        // return Observable.of({authenticated: true});
        if (sessionStorage.getItem('user')) {
            return of({ authenticated: true });
            //return Observable.of({authenticated: true});
        }
        else {
            return of({ authenticated: false });
            //return Observable.of({authenticated: false});
        }
    }
    /**
     * to check autentication of user
     *
     * @param none
     * @return observable of Observable
    */
    login(data) {
        return this.http.post(this._apiBase + 'user_signin.php', data);
    }
    loginwithsocial(data) {
        return this.http.post(this._apiBase + 'social_user.php', data);
    }
    signup(data) {
        return this.http.post(this._apiBase + 'user.php', data);
    }
    favData(user_id) {
        return this.http.get(this._apiBase + 'user_favorites.php?user_id=' + user_id);
    }
    updateProfile(data) {
        return this.http.put(this._apiBase + 'user.php', data);
    }
    forgetPasswd(data) {
        return this.http.post(this._apiBase + 'forget_password.php', data);
    }
    updatePassword(data) {
        return this.http.post(this._apiBase + 'user_passchange.php', data);
    }
    resetPassword(data) {
        return this.http.post(this._apiBase + 'user_auth.php', data);
    }
    country() {
        return this.http.get(this._apiBase + 'country.php');
    }
    countrylist() {
        return this.http.post(this._apiBase + 'web_country_list.php', {});
    }
    contactus(data) {
        return this.http.post(this._apiBase + 'web_contact_us.php', data);
    }
    suggeststation(data) {
        return this.http.post(this._apiBase + 'web_suggest_station.php', data);
    }
    faqlist() {
        return this.http.get(this._apiBase + 'faqlist.php');
    }
    faqdetail(slug) {
        return this.http.get(this._apiBase + 'full_faq.php?slug=' + slug);
    }
    // genredetail(slug):Observable<any> {
    // 	return this.http.get(this._apiBase + 'genre_desc.php?genre_name='+slug);
    //}
    faqsearch(slug) {
        return this.http.get(this._apiBase + 'faqlist_search.php?search=' + slug);
    }
    faqsub(f_id, status) {
        return this.http.post(this._apiBase + 'faq_response.php', { 'f_id': f_id, 'status': status });
    }
    bloglist() {
        return this.http.get(this._apiBase + 'bloglist.php');
    }
    blogdetail(slug) {
        return this.http.get(this._apiBase + 'full_blog.php?slug=' + slug);
    }
    newsletter(data) {
        return this.http.post(this._apiBase + 'newsletter.php', data);
    }
    test(slug) {
        return this.http.get(this._apiBase + 'testing.php?slug=' + slug);
    }
    getmetadetail(slug) {
        return this.http.get(this._apiBase + 'get_meta_detail.php?slug=' + slug);
    }
    blogpost(slug) {
        return this.http.get(this._apiBase + 'blog_subscribe.php?email=' + slug);
    }
    getgame() {
        return this.http.get('https://theonestopradio.com/api/games.php');
    }
    getwebgame() {
        return this.http.get('https://theonestopradio.com/api/web_game.php');
    }
    getrecord(data) {
        return this.http.post('https://theonestopradio.com/api/game_record.php', data);
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("apiBase")); }, token: AuthenticationService, providedIn: "root" });
