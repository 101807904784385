/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./detail.component";
import * as i4 from "@angular/router";
import * as i5 from "../../home/authentication.service";
import * as i6 from "ng4-loading-spinner";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/platform-browser";
var styles_DetailComponent = [i0.styles];
var RenderType_DetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DetailComponent, data: {} });
export { RenderType_DetailComponent as RenderType_DetailComponent };
function View_DetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "container gamesViewIframe"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["id", "myVideo"], ["scrolling", "yes"], ["title", "Iframe Example"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.controllerSrc; _ck(_v, 1, 0, currVal_0); }); }
export function View_DetailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-detail", [], null, null, null, View_DetailComponent_0, RenderType_DetailComponent)), i1.ɵdid(1, 114688, null, 0, i3.DetailComponent, [i4.Router, i4.ActivatedRoute, i5.AuthenticationService, i6.Ng4LoadingSpinnerService, i7.HttpClient, i8.DomSanitizer, i8.Title, i8.Meta, i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DetailComponentNgFactory = i1.ɵccf("app-detail", i3.DetailComponent, View_DetailComponent_Host_0, {}, {}, []);
export { DetailComponentNgFactory as DetailComponentNgFactory };
