import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CommonService {
    constructor(http) {
        this.http = http;
        this.activeTrack = new BehaviorSubject(null);
        this.currentTrack = this.activeTrack.asObservable();
        this.messageSource = new BehaviorSubject(null);
        this.currentMessage = this.messageSource.asObservable();
        this.isloading = new BehaviorSubject(true);
        this.currentLoader = this.isloading.asObservable();
        this.isDisabed = new BehaviorSubject(true);
        this.currentDisabled = this.isDisabed.asObservable();
        this.isLogin = new BehaviorSubject(false);
        this.currentisLogin = this.isLogin.asObservable();
        this.isfav = new BehaviorSubject(false);
        this.currentisFav = this.isfav.asObservable();
        this.isplayerror = new BehaviorSubject(false);
        this.currentiserror = this.isplayerror.asObservable();
        this.ispageload = new BehaviorSubject(false);
        this.currentispageload = this.ispageload.asObservable();
        this.isplay = new BehaviorSubject(false);
        this.currentisplay = this.isplay.asObservable();
        this.isend = new BehaviorSubject(false);
        this.currentisend = this.isend.asObservable();
        this.isvolume = new BehaviorSubject(0);
        this.currentvolume = this.isvolume.asObservable();
        this.isbvolume = new BehaviorSubject(0);
        this.currentbvolume = this.isbvolume.asObservable();
        this.isstart = new BehaviorSubject(0);
        this.currentStart = this.isstart.asObservable();
        this.ischeckfav = new BehaviorSubject(false);
        this.currentiscFav = this.ischeckfav.asObservable();
        this.issearchblank = new BehaviorSubject(false);
        this.currentissearch = this.issearchblank.asObservable();
        this.nextplaydata = new BehaviorSubject({});
        this.currentnextplay = this.nextplaydata.asObservable();
        this.isnext = new BehaviorSubject(false);
        this.currentisnext = this.isnext.asObservable();
        this.isprevNextplaying = new BehaviorSubject(false);
        this.currentisprevNextplaying = this.isprevNextplaying.asObservable();
        this.isstartplaying = new BehaviorSubject(null);
        this.currentisstartplaying = this.isstartplaying.asObservable();
        this.issearch = new BehaviorSubject(null);
        this.currentisssearch = this.issearch.asObservable();
    }
    changeCurrentTrack(track) {
        this.activeTrack.next(track);
    }
    changeMessage(message) {
        this.messageSource.next(message);
    }
    setLoader(state) {
        this.isloading.next(state);
    }
    setDisabled(state) {
        this.isDisabed.next(state);
    }
    setisLogin(state) {
        // console.log(' self.commonService.setLoader(false);',state);
        this.isLogin.next(state);
    }
    setisfav(state) {
        this.isfav.next(state);
    }
    setisplayerror(state) {
        this.isplayerror.next(state);
    }
    setispageload(state) {
        this.ispageload.next(state);
    }
    setisplay(state) {
        this.isplay.next(state);
    }
    setisend(state) {
        this.isend.next(state);
    }
    setvolume(state) {
        this.isvolume.next(state);
    }
    setbvolume(state) {
        this.isbvolume.next(state);
    }
    setcurrentStart(state) {
        this.isstart.next(state);
    }
    setiscfav(state) {
        this.ischeckfav.next(state);
    }
    setissearchstate(state) {
        this.issearchblank.next(state);
    }
    setcurrentnextplay(state) {
        this.nextplaydata.next(state);
    }
    setisnext(state) {
        this.isnext.next(state);
    }
    setisprevNextplaying(state) {
        this.isprevNextplaying.next(state);
    }
    setisstartplaying(state) {
        this.isstartplaying.next(state);
    }
    setissearch(state) {
        this.issearch.next(state);
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.HttpClient)); }, token: CommonService, providedIn: "root" });
