<div class="container gamesView">
    <header class="panelHead"><h1 class="h4">Games</h1></header>
    <div class="panel">
        <div class="panelBody gamesList">
            <div class="gameList"  *ngFor="let res of game; let i = index" [routerLink]="['/minigame/'+res.slug]" >
                <span class="thumb"><img src="{{res.Image}}" alt="{{res.Image}}"></span>
                <span class="info"><strong>{{res.name}}</strong> {{res.categories}} </span>
            </div>
        </div>
    </div>
</div>