import { Observable } from 'rxjs'; // only need to import from rxjs
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlayerService {
    constructor(http, _apiBase) {
        this.http = http;
        this._apiBase = _apiBase;
    }
    favourite(data) {
        return this.http.post(this._apiBase + 'user_favorites.php', data);
    }
    prevnext(data) {
        return this.http.post(this._apiBase + 'next_previous_st.php', data);
    }
    prevnextsearch(data) {
        return this.http.post(this._apiBase + 'next_previous_st.php', data);
    }
}
PlayerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerService_Factory() { return new PlayerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("apiBase")); }, token: PlayerService, providedIn: "root" });
